import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../../stores/actions/index";
import {
  getLan,
  setFormatCurrencyStringOrZero,
} from "../../../../stores/utils/utilities";

import classes from "./Cart.css";
const references = [];

const Cart = (props) => {
  const refContainerCard = React.createRef();
  const refClose = React.createRef();
  const refCart = React.createRef();
  references.push(refClose);
  references.push(refContainerCard);
  references.push(refCart);
  const dispatch = useDispatch();

  const checkoutRedux = useSelector((state) => state.cursos.checkout);

  useEffect(() => {
    if (checkoutRedux) {
      const url = checkoutRedux ? checkoutRedux.urlPago : null;
      dispatch(action.deletCheckout(null));
      localStorage.removeItem("shoppingCart");
      window.open(url, "_blank", "noopener,noreferrer");
    }
  }, [checkoutRedux]);

  let cartCard = null;
  const [animationCart, setAnimationCart] = useState(false);
  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState(null);
  const [total, setTotal] = useState(0);
  const [qtyTotal, setQtyTotal] = useState(0);

  const [products, setProducts] = useState([]);

  const [currentStorage, SyncWithLocalStorage] = useState(localStorage || {});

  const eventListenerFun = (e) => {
    SyncWithLocalStorage({ ...localStorage }); //<----spread it which will cause refrence to change
  };
  useEffect(() => {
    window.addEventListener("storage", eventListenerFun);

    return () => window.removeEventListener("storage", eventListenerFun);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnimationCart(false);
    }, 200);
    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // when we update it
  }, [animationCart]);

  const changeShoppingCart = () => {
    SyncWithLocalStorage({ ...localStorage }); //<----spread it which will cause refrence to change
    let items = JSON.parse(localStorage.getItem("shoppingCart"));
    if (items) {
      let totalAmount = 0;
      let qty = 0;
      items.forEach((product) => {
        totalAmount += product.precio * product.qty;
        qty += +product.qty;
      });
      setProducts(items);
      if (items == null) items = [];
      setProducts(items);
      if (qty !== qtyTotal && !animationCart) {
        setAnimationCart(true);
      }
      setTotal(totalAmount);
      setQtyTotal(qty);
      setNumber(qty);
    } else {
      setProducts([]);
      setQtyTotal(0);
      setNumber(null);
      setTotal(0);
    }
  };

  useEffect(() => {
    changeShoppingCart();
  }, [currentStorage]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  const handleClickOutside = (event) => {
    setOpen(validateOutSideReference(event));
  };

  const validateOutSideReference = (event) => {
    for (let reference of references) {
      if (
        reference &&
        reference.current &&
        reference.current.contains(event.target)
      ) {
        return true;
      }
    }
    return false;
  };

  const clickButton = (e, openCard) => {
    e.preventDefault();
    setOpen(!openCard);
  };

  const checkoutProducts = (e) => {
    const params = products.map((itm) => {
      return { idCurso: itm.id, cantidad: itm.qty };
    });
    dispatch(action.postCheckout(params));
  };
  const deleteProduct = (e, product) => {
    var items = JSON.parse(localStorage.getItem("shoppingCart"));
    if (items == null) items = [];
    items = items.filter((itm) => itm.id !== product.id);
    if (items && items.length === 0) items = [];

    localStorage.setItem("shoppingCart", JSON.stringify(items));
  };
  const changeQty = (e, item) => {
    e.preventDefault();
    var products = JSON.parse(localStorage.getItem("shoppingCart"));
    if (products == null) products = [];
    const existProduct = products.find((product) => product.id === item.id);
    var productsFilters = products;
    if (existProduct) {
      existProduct.qty =
        e.target.value > item.vacantes
          ? item.vacantes
          : e.target.value < 0
          ? 1
          : e.target.value;
    } else {
      item.qty = 1;
      productsFilters.push(item);
    }
    localStorage.setItem("shoppingCart", JSON.stringify(productsFilters));
  };

  if (open) {
    const productsDivs = products.map((itm) => {
      return (
        <div className={["col-12", classes.productContainer].join(" ")}>
          <div className="row">
            <div className={["col-3", classes.imgContainer].join(" ")}>
              <img src={itm.imagen} alt="" className="width_cien" />
            </div>
            <div className="col-9 sin_padding">
              <span className="montse texto_14 bold">{itm.titulo}</span>

              <div className={["form-group row", classes.forms].join(" ")}>
                <label
                  className={[
                    "nunito texto_12 sin_padding",
                    classes.textQty,
                  ].join(" ")}
                  for="checkoutId"
                >
                  {getLan() === "es" ? "Cantidad: " : "Quantity: "}
                </label>
                <input
                  type="number"
                  id="checkoutId"
                  className={[
                    "form-control regular  sin_padding",
                    classes.inputNumber,
                  ].join(" ")}
                  value={itm.qty}
                  min={1}
                  max={itm.vacantes}
                  pattern="[0-9]{10}"
                  onChange={(e) => changeQty(e, itm)}
                />
                <span
                  className={["nunito texto_12 ", classes.totalProduct].join(
                    " "
                  )}
                >
                  {" X " + setFormatCurrencyStringOrZero(itm.precio)}
                </span>
                <i
                  className={["fas fa-trash", classes.deleteIcon].join(" ")}
                  onClick={(e) => deleteProduct(e, itm)}
                ></i>
              </div>

              <div className={["form-group row", classes.forms].join(" ")}>
                <span
                  className={[
                    "nunito texto_12 text-right bold",
                    classes.total,
                  ].join(" ")}
                >
                  {setFormatCurrencyStringOrZero(itm.precio * itm.qty)}
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
      );
    });
    cartCard = (
      <div
        className={[
          classes.shopping_cart,
          classes.shopping_cart_right,
          "row",
        ].join(" ")}
      >
        <i
          className={["fas fa-times", classes.close].join(" ")}
          ref={refClose}
          onClick={(e) => setOpen(false)}
        ></i>

        <div className={classes.cart_content_box} ref={refCart}>
          {productsDivs}
          <div
            className={["col-12 margin_top_30", classes.totalContainer].join(
              " "
            )}
          >
            <div className={["form-group row", classes.forms].join(" ")}>
              <span
                className={["nunito texto_12 bold", classes.total].join(" ")}
              >
                {"Total: " + setFormatCurrencyStringOrZero(total)}
              </span>{" "}
            </div>
          </div>
          <div className={["col-12", classes.checkoutContainer].join(" ")}>
            <div
              className={[classes.botonCheckout, products.length === 0 ? classes.disabled : null].join(" ")}
              onClick={(e) => products.length === 0 ? null : checkoutProducts()}
            >
              <span className="montse texto_16 bold texto_blanco">
                CHECKOUT
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={classes.cartContainer}>
        <div
          className={[
            classes.cart,
            classes.cartRight,
            animationCart ? null : classes.active,
          ].join(" ")}
          onClick={(e) => clickButton(e, false)}
        >
          <i className={["fas fa-shopping-cart", number ? classes.cartNumberAnimated : null].join(" ")}></i>
          <span className={["nunito bold", classes.cartNumber,
            number ? classes.cartNumberAnimated : null].join(" ")}>
            {number}
          </span>
        </div>
        <div
          className={[
            classes.cartTextBox,
            classes.active,
            classes.cartRight,
            "texto_16 nunito bold",
          ].join(" ")}
          onClick={(e) => clickButton(e, false)}
        >
          Carrito
        </div>
      </div>
      <div className={classes.cartContainer}>
        {cartCard}
      </div>
    </>
  );
};

export default Cart;
